import React from 'react';
import { Button } from '@/components/ui/button';
import { loadStripe } from '@stripe/stripe-js';
import { useToast } from "@/hooks/use-toast";
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '@/config';

const STRIPE_PUBLIC_KEY = 'pk_test_51Q6VrX2Lm56OzpiYyRd6Q3GBO1H9ueb9nAUuzZJbiQmmCLcQkVnDyIjgXDOBMCGdVKDdaGuDPTLcfvBNWH4OUoLr00xcHWwf81';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface CheckoutButtonProps {
  postId: number;
  price: number;
  uuid: string;
  className?: string;
}

export function CheckoutButton({ postId, price, uuid, className }: CheckoutButtonProps) {
  const { toast } = useToast();

  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe n\'a pas pu être chargé');

      const response = await axiosInstance.post(`${REACT_APP_API_URL}/payments/create-checkout-session`, {
        postId,
        price,
        uuid
      });

      const { sessionId } = response.data;

      if (!sessionId) throw new Error('Session ID non reçu');

      // Rediriger vers Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        console.error('Stripe error:', error);
        throw error;
      }
    } catch (error: any) {
      console.error('Checkout error:', error);
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de l'initialisation du paiement",
      });
    }
  };

  return (
    <Button
      onClick={handleCheckout}
      className={className}
    >
      Acheter maintenant
    </Button>
  );
}