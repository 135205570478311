import { SelectContent, SelectValue } from "@/components/ui/select";

import { SelectTrigger } from "@/components/ui/select";

import { Select } from "@/components/ui/select";

import { Input } from "@/components/ui/input";
import { SelectItem } from "@/components/ui/select";

interface SalesFiltersProps {
    searchQuery: string;
    filterStatus: string;
    onSearchChange: (value: string) => void;
    onStatusChange: (value: string) => void;
  }

export function TableFilter({
  searchQuery,
  filterStatus,
  onSearchChange,
  onStatusChange,
  }: SalesFiltersProps) {
    return (
      <div className="flex gap-4 mb-6">
        <Input
          placeholder="Rechercher une vente..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          className="max-w-sm"
        />
        <Select value={filterStatus} onValueChange={onStatusChange}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filtrer par statut" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">Tous les statuts</SelectItem>
            <SelectItem value="pending">En attente</SelectItem>
            <SelectItem value="completed">Complété</SelectItem>
            <SelectItem value="cancelled">Annulé</SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
  }
