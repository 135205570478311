import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { updatePost, deletePost } from '@/app/reducers/PostsReducer';
import { AppDispatch } from '@/app/store';
import { Post } from '@/components/services/types/posts';
import Modal from '@/components/shared/Modal';
import { EuroIcon } from 'lucide-react';

interface EditPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post | null;
}

function EditPostModal({ isOpen, onClose, post }: EditPostModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [preview, setPreview] = useState<string | null>(null);
  const [status, setStatus] = useState(post?.status || 'active');
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    if (post && isOpen) {
      setIsImageLoading(true);
      setPreview(null);

      const newFormData = new FormData();
      Object.entries(post).forEach(([key, value]) => {
        if (value !== null) {
          newFormData.append(key, value.toString());
        }
      });
      setFormData(newFormData);

      setTimeout(() => {
        setPreview(post.image_url);
      }, 100);

      setStatus(post.status);
    }
  }, [post, isOpen]);

  const resetModal = () => {
    if (!post) {
      setFormData(new FormData());
      setPreview(null);
      setStatus('active');
    }
    setIsImageLoading(false);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newFormData = new FormData();
    formData.forEach((value, key) => newFormData.append(key, value));
    newFormData.set(e.target.name, e.target.value);
    setFormData(newFormData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setIsImageLoading(true);

      const newFormData = new FormData();
      formData.forEach((value, key) => newFormData.append(key, value));
      newFormData.set('image', file);
      setFormData(newFormData);

      const reader = new FileReader();
      reader.onloadend = () => {
        setTimeout(() => {
          setPreview(reader.result as string);
          setIsImageLoading(false);
        }, 1000);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectChange = (value: string) => {
    setStatus(value as 'active' | 'inactive');
    const newFormData = new FormData();
    formData.forEach((value, key) => newFormData.append(key, value));
    newFormData.set('status', value);
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    if (post?.id) {
      dispatch(updatePost({ id: post.id, formData }));
      handleClose();
    }
  };

  const handleDelete = () => {
    if (post?.id) {
      const confirmDelete = window.confirm('Voulez-vous vraiment supprimer cette publication ?');
      if (confirmDelete) {
        dispatch(deletePost(post.id));
        handleClose();
      }
    }
  };

  if (!post) return null;

  const modalContent = (
    <div className="flex gap-8 h-[600px]">
      {/* Colonne de gauche - Formulaire */}
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex-[1.2] overflow-y-auto pr-4 px-2"
        aria-label="Modifier la publication"
      >
        <div className="grid grid-cols-1 gap-6">
          <div>
            <Label htmlFor="title" className="text-lg font-semibold">
              Titre de la publication <span aria-hidden="true">*</span>
            </Label>
            <Input
              id="title"
              name="title"
              defaultValue={post?.title}
              onChange={handleInputChange}
              required
              className="mt-2 h-12 text-lg"
              placeholder="Entrez un titre accrocheur"
              aria-required="true"
              aria-invalid={!formData.get('title')}
              aria-describedby="title-error"
            />
          </div>

          <div className="grid grid-cols-2 gap-4 ">
            <div className="space-y-2 ">
              <Label htmlFor="price" className="text-lg font-semibold">
                Prix <span aria-hidden="true">*</span>
              </Label>
              <div className="relative">
                <Input
                  id="price"
                  name="price"
                  type="number"
                  step="0.01"
                  defaultValue={post?.price}
                  onChange={handleInputChange}
                  required
                  className="h-12 text-lg pl-12 font-semibold"
                  placeholder="0.00"
                  aria-required="true"
                  aria-invalid={!formData.get('price')}
                  aria-describedby="price-error"
                />
                <EuroIcon
                  className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-muted-foreground"
                  aria-hidden="true"
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="status" className="text-lg font-semibold">
                Statut <span aria-hidden="true">*</span>
              </Label>
              <Select
                value={status}
                onValueChange={handleSelectChange}
                name="status"
                aria-required="true"
                aria-label="Sélectionner le statut"
              >
                <SelectTrigger className="h-12">
                  <SelectValue placeholder="Sélectionnez un statut" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="active">
                    <span className="flex items-center gap-2">
                      <span className="h-2 w-2 rounded-full bg-green-500"></span>
                      Actif
                    </span>
                  </SelectItem>
                  <SelectItem value="inactive">
                    <span className="flex items-center gap-2">
                      <span className="h-2 w-2 rounded-full bg-red-500"></span>
                      Inactif
                    </span>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div>
            <Label htmlFor="image" className="text-lg font-semibold">
              Image
            </Label>
            <Input
              id="image"
              name="image"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="mt-2 h-12 text-base"
              aria-label="Sélectionner une image"
              aria-describedby="image-description"
            />
          </div>
        </div>
      </form>

      {/* Colonne de droite - Image */}
      <div
        className="flex-[2] border-l border-border pl-8"
        role="region"
        aria-label="Prévisualisation de l'image"
      >
        <div
          className="h-full flex items-center justify-center border-2 border-dashed rounded-xl bg-muted/50"
          role="img"
          aria-label={preview ? "Prévisualisation de l'image" : "Aucune image sélectionnée"}
        >
          {preview ? (
            <div className="relative w-full h-full">
              <img
                src={preview}
                alt="Prévisualisation"
                className="max-w-full h-full object-contain rounded-lg p-2 transition-opacity duration-300"
                onLoadStart={() => setIsImageLoading(true)}
                onLoad={() => {
                  setTimeout(() => {
                    setIsImageLoading(false);
                  }, 500);
                }}
                style={{ opacity: isImageLoading ? 0 : 1 }}
              />
              {isImageLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="flex flex-col items-center gap-4">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                    <p className="text-sm text-muted-foreground">Chargement de l'image...</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="text-base text-muted-foreground text-center p-8">
              <div className="mb-4 text-4xl">🖼️</div>
              <div className="font-medium mb-2">Aucune image sélectionnée</div>
              <div className="text-sm">Format recommandé : 1200x800px</div>
              <div className="text-xs text-muted-foreground mt-2">JPG, PNG ou GIF jusqu'à 10MB</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Modifier la publication"
      className="max-w-[1200px] w-[90vw]"
      buttons={[
        {
          label: 'Supprimer la publication',
          onClick: handleDelete,
          variant: 'destructive' as const,
        },
        {
          label: 'Enregistrer les modifications',
          onClick: handleSubmit,
          variant: 'default' as const,
        }
      ]}
    >
      {modalContent}
    </Modal>
  );
}

export default EditPostModal;
