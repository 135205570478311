import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface TestimonialProps {
  image: string;
  name: string;
  userName: string;
  comment: string;
}

const testimonials: TestimonialProps[] = [
  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=alice",
    name: "Sophie Martin",
    userName: "@sophie_crea",
    comment: "Trop contente d'avoir trouv\u00E9 cette plateforme ! J'avais du mal avant pour vendre mes cr\u00E9ations digitales, maintenant c'est r\u00E9gl\u00E9 en deux clics. Un vrai gain de temps !",
  },

  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=Thoma",
    name: "Thomas Dubois",
    userName: "@tom_photo",
    comment: "\u00C7a fait 2 mois que je vends mes presets photo ici et franchement, c'est nickel ! Mes clients adorent la simplicit\u00E9 du syst\u00E8me, plus personne ne me pose de questions.",
  },
  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=Minyou",
    name: "Minyou",
    userName: "@minyou_cosplay",
    comment: "Tellement plus simple pour vendre mes photos de cosplay !",
  },
  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=Marie",
    name: "Marie Lambert",
    userName: "@marie_coach",
    comment: "Plus besoin de jongler entre 10 apps diff\u00E9rentes pour vendre mes programmes de coaching. Tout est au m\u00EAme endroit, c'est pro et super pratique !",
  },
  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=Lucas",
    name: "Lucas Bernard",
    userName: "@lucas_art",
    comment: "Enfin une solution qui marche bien ! Je mets mes ressources graphiques en ligne en 2 minutes, et je peux suivre mes ventes direct sur mon t\u00E9l\u00E9phone. Vraiment top !",
  },
  {
    image: "https://api.dicebear.com/7.x/avataaars/svg?seed=elisa",
    name: "elisa Petit",
    userName: "@elisa_writes",
    comment: "J'arrive \u00E0 vendre mes ebooks et aucun soucis. Franchement, c'est exactement ce qu'il me fallait ! Z\u00E9ro prise de t\u00EAte.",
  },

];

export const Testimonials = () => {
  return (
    <section
      id="testimonials"
      className="container py-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold">
        Découvrez pourquoi vous pouvez nous faire
        <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
          {" "}
           confiance
        </span>
      </h2>

      <p className="text-xl text-muted-foreground pt-4 pb-8">
        Liste d'avis de nos utilisateurs
      </p>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 sm:block columns-2  lg:columns-3 lg:gap-6 mx-auto space-y-4 lg:space-y-6">
        {testimonials.map(
          ({ image, name, userName, comment }: TestimonialProps) => (
            <Card
              key={userName}
              className="max-w-md md:break-inside-avoid overflow-hidden"
            >
              <CardHeader className="flex flex-row items-center gap-4 pb-2">
                <Avatar>
                  <AvatarImage
                    alt=""
                    src={image}
                  />
                  <AvatarFallback>OM</AvatarFallback>
                </Avatar>

                <div className="flex flex-col">
                  <CardTitle className="text-lg">{name}</CardTitle>
                  <CardDescription>{userName}</CardDescription>
                </div>
              </CardHeader>

              <CardContent>{comment}</CardContent>
            </Card>
          )
        )}
      </div>
    </section>
  );
};
