import { SalesByPost } from "@/components/services/types/sales";

export function useSalesFilter(sales: any, filterStatus: string, searchQuery: string) {
  const filteredSales = Object.entries(sales).filter(([_, sale]: any) => {
    const matchesStatus =
      filterStatus === "all" ||
      sale.sales.some((s: any) => s.status === filterStatus);
    const matchesSearch = sale.post.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  const totalPages = Math.ceil(filteredSales.length / 10);

  return { filteredSales, totalPages };
}
