import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/app/store';
import { fetchWalletBalance, withdrawFunds } from '@/app/reducers/SalesReducer';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/hooks/use-toast';
import { Euro, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function WalletPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { walletBalance, isLoading, error } = useSelector((state: RootState) => state.sales);

  useEffect(() => {
    dispatch(fetchWalletBalance());
  }, [dispatch]);

  useEffect(() => {
    if (!walletBalance?.available) {
      toast({
        title: "Configuration requise",
        description: (
          <div className="flex flex-col gap-2">
            <span>Pour recevoir vos paiements, vous devez configurer votre compte Stripe Connect.</span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigate('/settings/stripe')}
            >
              Configurer maintenant
            </Button>
          </div>
        ),
        duration: 5000,
      });
    }
  }, [walletBalance]);

  useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error,
      });
    }
  }, [error]);

  const handleWithdraw = async () => {
    if (!walletBalance?.available) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Aucun montant disponible pour le retrait",
      });
      return;
    }

    try {
      await dispatch(withdrawFunds(walletBalance.available)).unwrap();
      toast({
        title: "Succès",
        description: "Votre retrait a été effectué avec succès",
      });
      dispatch(fetchWalletBalance());
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors du retrait",
      });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Mon Portefeuille</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <Card className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-muted-foreground">Disponible</p>
              <h3 className="text-2xl font-bold">{walletBalance?.available || 0}€</h3>
            </div>
            <Euro className="h-8 w-8 text-green-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-muted-foreground">En attente</p>
              <h3 className="text-2xl font-bold">{walletBalance?.pending || 0}€</h3>
            </div>
            <Euro className="h-8 w-8 text-yellow-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-muted-foreground">Échoué</p>
              <h3 className="text-2xl font-bold">{walletBalance?.failed || 0}€</h3>
            </div>
            <Euro className="h-8 w-8 text-red-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-muted-foreground">Remboursé</p>
              <h3 className="text-2xl font-bold">{walletBalance?.refunded || 0}€</h3>
            </div>
            <Euro className="h-8 w-8 text-gray-500" />
          </div>
        </Card>
      </div>

      <div className="flex justify-center">
        <Button
          onClick={handleWithdraw}
          disabled={!walletBalance?.available || isLoading}
          className="w-full md:w-auto"
        >
          {isLoading ? "Traitement en cours..." : "Retirer les fonds"}
        </Button>
      </div>
    </div>
  );
}
