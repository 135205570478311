import { PrettyLogin } from '@/components/services/auth/loginForm'

function Login() {

  return (
    <div>
      <PrettyLogin />
    </div>
  )
}

export default Login
