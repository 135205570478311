import { Badge } from "@/components/ui/badge";
import TableComponent from "@/components/shared/Table";
import { Post } from "@/components/services/types/posts";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Button } from "@/components/ui/button";
import { Share2, Pencil } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { Toaster } from '@/components/ui/toaster';
import ShareModal from './ShareModal';
import { useState } from 'react';

interface PostsTableProps {
  postData: Post[];
  onEditPost: (post: Post) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const normalizeString = (str: string): string => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export const PostsTable = ({
  postData,
  onEditPost,
  currentPage,
  totalPages,
  onPageChange,
}: PostsTableProps) => {
  const { toast } = useToast();
  const isLoading = useSelector((state: RootState) => state.posts.isLoading);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedPostUuid, setSelectedPostUuid] = useState<string>('');
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  const handleShare = (uuid: string) => {
    setSelectedPostUuid(uuid);
    setSelectedPost(postData.find(post => post.uuid === uuid) || null);
    setShareModalOpen(true);
  };

  const getStatusStyle = (status: string): { backgroundColor: string; color: string } => {
    const normalizedStatus = normalizeString(status);
    switch (normalizedStatus) {
      case "active":
        return { backgroundColor: "hsl(var(--primary))", color: "hsl(var(--primary-foreground))" };
      case "inactive":
        return { backgroundColor: "hsl(var(--destructive))", color: "hsl(var(--destructive-foreground))" };
      default:
        return { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" };
    }
  };

  return (
    <>
      <Toaster />
      <TableComponent
        data={postData}
        columns={[
          {
            header: "Statut",
            accessor: "status",
            className: "hidden sm:table-cell",
            cell: (post) => (
              <Badge
                variant="custom"
                className="text-xs"
                customStyle={getStatusStyle(post.status)}
              >
                {post.status}
              </Badge>
            ),
            sortable: true,
          },
          {
            header: "Date",
            accessor: "created_at",
            className: "hidden md:table-cell",
            cell: (post) => formatDistanceToNow(new Date(post.created_at), {
              addSuffix: true,
              locale: fr
            }),
            sortable: true,
          },
          {
            header: "Prix",
            accessor: "price",
            className: "text-right",
            cell: (post) => `${post.price} €`,
            sortable: true,
          },
          {
            header: "",
            actions: (post) => (
              <div className="flex justify-end gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShare(post.uuid);
                  }}
                  className="h-8 flex items-center gap-2 text-primary hover:text-primary-foreground hover:bg-primary"
                >
                  <Share2 className="h-4 w-4" />
                  <span className="hidden sm:inline">Partager</span>
                </Button>
              </div>
            ),
          }
        ]}
        onRowClick={onEditPost}
        itemsPerPage={5}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        isLoading={isLoading}
      />

      <ShareModal
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        postUuid={selectedPostUuid}
        postTitle={selectedPost?.title}
        postImage={selectedPost?.image_url}
      />
    </>
  );
};
