import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import image from "@/assets/growth.png";
import image3 from "@/assets/reflecting.png";
import image4 from "@/assets/looking-ahead.png";

interface FeatureProps {
  title: string;
  description: string;
  image: string;
}

const features: FeatureProps[] = [
  {
    title: "Compatible mobile",
    description:
      "Une conception pens\u00E9e pour tous les plateformes, mobile et desktop.",
    image: image4,
  },
  {
    title: "Simplicit\u00E9 de paiement",
    description:
      "Notre site est con\u00E7u pour un paiement rapide et s\u00E9curis\u00E9.",
    image: image3,
  },
  {
    title: "Commission r\u00E9duite",
    description:
      "Contrairement \u00E0 nos concurrents, nous ne prenons que 15% de commission.",
    image: image,
  },
];

const featureList: string[] = [
  "Tarification transparente",
  "Notre equipe d'experts",
  "Adapte au mobile",
  "Interface epuree",
  "Commission reduite",
  "Paiements securises",
  "Support client reactif"
];

export const Features = () => {
  return (
    <section
      id="features"
      className="container py-24 sm:py-32 space-y-8"
    >
      <h2 className="text-3xl lg:text-4xl font-bold md:text-center">
        De nombreuses{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
           fonctionnalités
        </span>
      </h2>

      <div className="flex flex-wrap md:justify-center gap-4">
        {featureList.map((feature: string) => (
          <div key={feature}>
            <Badge
              variant="secondary"
              className="text-sm"
            >
              {feature}
            </Badge>
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map(({ title, description, image }: FeatureProps) => (
          <Card key={title}>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardContent>{description}</CardContent>

            <CardFooter>
              <img
                src={image}
                alt="About feature"
                className="w-[200px] lg:w-[300px] mx-auto"
              />
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
