import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

// Importez vos composants de page ici
import Login from './pages/Login';
import ProfilePage from './pages/ProfilePage';
import Register from './pages/Register';
import DashboardPage from './pages/DashboardPage';
import Layout from '@/components/core/Layouts';
import PaymentPage from './pages/Paiments';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Success from '@/components/services/stripe/Success';
import LandingPages from './pages/LandingPages';
import SharePage from './pages/SharePage';
import SalesPage from './pages/SalesPage';
import WalletPage from './pages/WalletPage';
import StripeConnectPage from './pages/StripeConnectPage';

const stripePromise = loadStripe(
  'pk_test_51O5unuFEj403Phjgbi9HfcgZSe8NW3jkyu9L47FNAx8dwXknEndmFqYhlmqo2BksV1Uwsv1rfo0s3ZeeZgQYuxSn00P9W9WRNc'
);

// Composant AuthGuard
const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to='/login' />;
};

export const AppRoutes = () => (
  <Routes>
    <Route
      path='/'
      element={
        <Elements stripe={stripePromise}>
          <LandingPages />
        </Elements>
      }
    />
    <Route
      path='/dashboard'
      element={
        <AuthGuard>
          <Layout>
            <DashboardPage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route path='/login' element={<Login />} />
    <Route path='/register' element={<Register />} />
    <Route
      path='/profile'
      element={
        <AuthGuard>
          <Layout>
            <ProfilePage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route
      path='/payment'
      element={
        <AuthGuard>
          <Layout>
            <Elements stripe={stripePromise}>
              <PaymentPage />
            </Elements>
          </Layout>
        </AuthGuard>
      }
    />

    <Route
      path='/landing'
      element={
        <Elements stripe={stripePromise}>
          <LandingPages />
        </Elements>
      }
    />
    <Route
      path='/success'
      element={
        <Elements stripe={stripePromise}>
          <Success />
        </Elements>
      }
    />

    <Route path='/sales' element={
      <AuthGuard>
        <Layout>
          <SalesPage />
        </Layout>
      </AuthGuard>
    } />
    <Route path='/share/:uuid' element={<SharePage />} />
    <Route
      path="/wallet"
      element={
        <AuthGuard>
          <Layout>
            <WalletPage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route
      path="/settings/stripe"
      element={
        <AuthGuard>
          <Layout>
            <StripeConnectPage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route
      path="/settings/stripe/refresh"
      element={
        <AuthGuard>
          <Layout>
            <StripeConnectPage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route
      path="/settings/stripe/return"
      element={
        <AuthGuard>
          <Layout>
            <StripeConnectPage />
          </Layout>
        </AuthGuard>
      }
    />
    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);
