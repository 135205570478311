import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '../../config';
import { Sale } from '@/components/services/types/posts';
interface SalesStats {
  totalAmount: number;
  totalCount: number;
}

interface WalletBalance {
  available: number;
  pending: number;
  failed: number;
  refunded: number;
}

interface SalesState {
  sales: Sale[];
  isLoading: boolean;
  error: string | null;
  salesStats: SalesStats | null;
  walletBalance: WalletBalance | null;
}

const initialState: SalesState = {
  sales: [],
  isLoading: false,
  error: null,
  salesStats: null,
  walletBalance: null,
};

// Thunks
export const fetchSales = createAsyncThunk(
  'sales/fetchSales',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/sales`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la récupération des ventes');
    }
  }
);

export const createSale = createAsyncThunk(
  'sales/createSale',
  async (postId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/sales`, { postId });
      return response.data.sale;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création de la vente');
    }
  }
);

export const fetchSalesStats = createAsyncThunk(
  'sales/fetchSalesStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/sales/stats`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la récupération des statistiques');
    }
  }
);

export const fetchWalletBalance = createAsyncThunk(
  'sales/fetchWalletBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/sales/wallet-balance`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la récupération du solde');
    }
  }
);

export const withdrawFunds = createAsyncThunk(
  'sales/withdrawFunds',
  async (amount: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/sales/withdraw`, { amount });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du retrait');
    }
  }
);

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Sales
      .addCase(fetchSales.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sales = action.payload;
      })
      .addCase(fetchSales.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Create Sale
      .addCase(createSale.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sales.unshift(action.payload);
      })
      .addCase(createSale.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Fetch Sales Stats
      .addCase(fetchSalesStats.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSalesStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.salesStats = {
          totalAmount: action.payload.totalAmount,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(fetchSalesStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Fetch Wallet Balance
      .addCase(fetchWalletBalance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWalletBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.walletBalance = action.payload;
      })
      .addCase(fetchWalletBalance.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Withdraw Funds
      .addCase(withdrawFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(withdrawFunds.fulfilled, (state) => {
        state.isLoading = false;
        // Rafraîchir le solde après un retrait réussi
      })
      .addCase(withdrawFunds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default salesSlice.reducer;
