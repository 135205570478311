import React from 'react'
import { PrettySignup } from '@/components/services/auth/registerForm'

function Register() {
  return (
    <div>
      <PrettySignup />
    </div>
  )
}

export default Register
