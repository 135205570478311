import TableComponent from "@/components/shared/Table";
import { Badge } from "@/components/ui/badge";
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { Sale, SalesByPost } from "@/components/services/types/sales";

interface SalesTableProps {
  salesData: SalesByPost;
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

export const SalesTable = ({
  salesData,
  currentPage,
  totalPages,
  onPageChange,
}: SalesTableProps) => {
  const isLoading = useSelector((state: RootState) => state.sales.isLoading);

  // Transformer les données groupées en tableau plat pour le tableau
  const flattenedSales = Object.values(salesData).flatMap(({ post, sales }) =>
    sales.map(sale => ({
      ...sale,
      postTitle: post.title,
    }))
  );

  const getStatusStyle = (status: string): { backgroundColor: string; color: string } => {
    switch (status.toLowerCase()) {
      case "completed":
        return { backgroundColor: "hsl(var(--primary))", color: "hsl(var(--primary-foreground))" };
      case "pending":
        return { backgroundColor: "#FDE68A", color: "#000000" };
      case "failed":
        return { backgroundColor: "#F43F5E", color: "#FFFFFF" };
      default:
        return { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" };
    }
  };

  return (
    <TableComponent
      data={flattenedSales}
      columns={[
        {
          header: "Contenu",
          accessor: "postTitle",
          cell: (sale) => sale.postTitle,
          sortable: true,
        },
        {
          header: "Date",
          accessor: "created_at",
          className: "hidden md:table-cell",
          cell: (sale) => {
            try {
              if (!sale.created_at || sale.created_at === null || sale.created_at === undefined) {
                return "Aucune date";
              }
              const date = new Date(sale.created_at);
              if (isNaN(date.getTime())) {
                return "Date invalide";
              }
              return formatDistanceToNow(date, {
                addSuffix: true,
                locale: fr
              });
            } catch (error) {
              return "Date invalide";
            }
          },
          sortable: true,
        },
        {
          header: "Statut",
          accessor: "status",
          cell: (sale) => (
            <Badge
              variant="custom"
              className="text-xs"
              customStyle={getStatusStyle(sale.status)}
            >
              {sale.status}
            </Badge>
          ),
          sortable: true,
        },
        {
          header: "Montant",
          accessor: "amount",
          className: "text-right",
          cell: (sale) => `${sale.amount} €`,
          sortable: true,
        },
      ]}
      itemsPerPage={10}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      isLoading={isLoading}
    />
  );
};
