import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export const Cta = () => {
  return (
    <section
      id="cta"
      className="bg-muted/50 py-16 my-24 sm:my-32"
    >
      <div className="container lg:grid lg:grid-cols-2 place-items-center">
        <div className="lg:col-start-1">
          <h2 className="text-3xl md:text-4xl font-bold ">
            Toutes vos
            <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
              {" "}
              publications et revenus{" "}
            </span>
            dans une seule interface
          </h2>
          <p className="text-muted-foreground text-xl mt-4 mb-8 lg:mb-0">
            Notre dashboard vous récapitule toutes vos publications, les revenus générés, et les statistiques associées.
          </p>
        </div>

        <div className="space-y-4 lg:col-start-2">
          <Link to="/login">
            <Button className="w-full md:mr-4 md:w-auto">Je me lance</Button>
          </Link>
          <a href="#testimonials">
            <Button
            variant="outline"
            className="w-full md:w-auto"
          >
              J'hesite encore
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};
