import { AppDispatch, RootState } from "@/app/store";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSales } from "@/app/reducers/SalesReducer";
import { SalesTable } from "@/components/services/sales/TablesSales";
import { SalesByPost } from "@/components/services/types/sales";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { TableFilter } from "@/components/services/sales/TableFilter";
import { useSalesFilter } from "@/hooks/useSalesFilter";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/components/ui/card";
import { TrendingUp, Clock, Wallet, XCircle } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";

function SalesPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { sales, isLoading } = useSelector((state: RootState) => state.sales);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    dispatch(fetchSales());
  }, [dispatch]);

  const salesData = useMemo(() => {
    return sales && typeof sales === "object" ? sales : {};
  }, [sales]);

  const salesStats = useMemo(() => {
    if (!salesData)
      return {
        completed: { count: 0, total: 0 },
        pending: { count: 0, total: 0 },
        failed: { count: 0, total: 0 },
      };

    const allSales = Object.values(salesData).reduce(
      (acc: any[], postSales: any) => {
        if (postSales.sales && Array.isArray(postSales.sales)) {
          return [...acc, ...postSales.sales];
        }
        return acc;
      },
      []
    );

    const completed = allSales.filter((sale) => sale?.status === "completed");
    const pending = allSales.filter((sale) => sale?.status === "pending");
    const failed = allSales.filter((sale) => sale?.status === "failed");

    return {
      completed: {
        count: completed.length,
        total: completed.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
      pending: {
        count: pending.length,
        total: pending.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
      failed: {
        count: failed.length,
        total: failed.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
    };
  }, [salesData]);

  const { filteredSales, totalPages } = useSalesFilter(
    salesData,
    filterStatus,
    searchQuery
  );

  const handleWithdraw = () => {
    console.log("Retrait demandé:", salesStats.completed.total);
  };

  return (
    <div className="grid auto-rows-max items-start gap-4 md:gap-8 p-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Mes Ventes</h1>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div>
              <CardTitle>Cagnotte</CardTitle>
              <CardDescription>Montant disponible</CardDescription>
            </div>
            <Wallet className="h-5 w-5 text-blue-600 dark:text-blue-400" />
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-blue-600 dark:text-blue-400">
              {salesStats.completed.total.toFixed(2)}€
            </div>
            <p className="text-muted-foreground mb-2">
              Disponible pour retrait
            </p>
            <Button onClick={handleWithdraw} className="w-full">
              <Wallet className="mr-2 h-4 w-4" />
              Retirer les fonds
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div>
              <CardTitle>Ventes Complétées</CardTitle>
              <CardDescription>Transactions finalisées</CardDescription>
            </div>
            <TrendingUp className="h-5 w-5 text-emerald-600 dark:text-emerald-400" />
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-emerald-600 dark:text-emerald-400">
              {salesStats.completed.count}
            </div>
            <p className="text-muted-foreground mb-2">
              Total: {salesStats.completed.total.toFixed(2)}€
            </p>
            <Progress
              value={salesStats.completed.count ?
                (salesStats.completed.count / (salesStats.completed.count + salesStats.pending.count)) * 100
                : 0
              }
              className="h-2 bg-emerald-100 dark:bg-emerald-900"
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div>
              <CardTitle>En Attente</CardTitle>
              <CardDescription>Transactions en cours</CardDescription>
            </div>
            <Clock className="h-5 w-5 text-yellow-600 dark:text-yellow-400" />
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-yellow-600 dark:text-yellow-400">
              {salesStats.pending.count}
            </div>
            <p className="text-muted-foreground mb-2">
              Total: {salesStats.pending.total.toFixed(2)}€
            </p>
            <Progress
              value={salesStats.pending.count ?
                (salesStats.pending.count / (salesStats.completed.count + salesStats.pending.count)) * 100
                : 0
              }
              className="h-2 bg-yellow-100 dark:bg-yellow-900 [&>div]:bg-yellow-600 dark:[&>div]:bg-yellow-400"
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div>
              <CardTitle>Refusées</CardTitle>
              <CardDescription>Transactions échouées</CardDescription>
            </div>
            <XCircle className="h-5 w-5 text-rose-600 dark:text-rose-400" />
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-rose-600 dark:text-rose-400">
              {salesStats.failed.count}
            </div>
            <p className="text-muted-foreground mb-2">
              Total: {salesStats.failed.total.toFixed(2)}€
            </p>
            <Progress
              value={salesStats.failed.count ?
                (salesStats.failed.count / (salesStats.completed.count + salesStats.failed.count)) * 100
                : 0
              }
              className="h-2 bg-rose-100 dark:bg-rose-900 [&>div]:bg-rose-600 dark:[&>div]:bg-rose-400"
            />
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Historique des ventes</CardTitle>
          <CardDescription>
            Gérez et suivez toutes vos transactions
          </CardDescription>
        </CardHeader>
        <CardContent>
          <TableFilter
            searchQuery={searchQuery}
            filterStatus={filterStatus}
            onSearchChange={setSearchQuery}
            onStatusChange={setFilterStatus}
          />

          <SalesTable
            salesData={Object.fromEntries(filteredSales) as unknown as SalesByPost}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default SalesPage;
