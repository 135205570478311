import { useToast } from "@/hooks/use-toast"
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"
import { motion, AnimatePresence } from "framer-motion"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      <AnimatePresence mode="sync">
        {toasts.map(function ({ id, title, description, action, ...props }: any) {
          return (
            <motion.div
              key={id}
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 40,
                mass: 1
              }}
              className="shadow-[0_0_15px_rgba(0,200,0,0.07)] dark:shadow-[0_0_15px_rgba(0,255,0,0.1)]"
            >
              <Toast
                {...props}
                className="shadow-lg backdrop-blur-sm bg-background/95"
              >
                <div className="grid gap-1">
                  {title && (
                    <ToastTitle className="animate-in slide-in-from-bottom-1">
                      {title}
                    </ToastTitle>
                  )}
                  {description && (
                    <ToastDescription className="animate-in slide-in-from-bottom-2">
                      {description}
                    </ToastDescription>
                  )}
                </div>
                {action}
                <ToastClose />
              </Toast>
            </motion.div>
          )
        })}
      </AnimatePresence>
      <ToastViewport />
    </ToastProvider>
  )
}
