import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '../../config';

interface StripeConnectState {
  status: 'not_connected' | 'pending' | 'active' | null;
  onboardingUrl: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: StripeConnectState = {
  status: null,
  onboardingUrl: null,
  isLoading: false,
  error: null,
};

export const createStripeConnect = createAsyncThunk(
  'users/createStripeConnect',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/users/connect`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création du compte Stripe Connect');
    }
  }
);

export const getStripeConnectStatus = createAsyncThunk(
  'users/getStripeConnectStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/users/connect/status`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la récupération du statut Stripe Connect');
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetStripeConnect: (state) => {
      state.onboardingUrl = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Stripe Connect
      .addCase(createStripeConnect.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createStripeConnect.fulfilled, (state, action) => {
        state.isLoading = false;
        state.onboardingUrl = action.payload.url;
      })
      .addCase(createStripeConnect.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Get Stripe Connect Status
      .addCase(getStripeConnectStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStripeConnectStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = action.payload.status;
      })
      .addCase(getStripeConnectStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetStripeConnect } = usersSlice.actions;
export default usersSlice.reducer;