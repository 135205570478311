'use client'

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Lock, Mail, AlertCircle } from "lucide-react"
import { Form, Field } from 'react-final-form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../../app/reducers/AuthReducers'
import { AppDispatch, RootState } from '../../../app/store' // Assurez-vous que ce chemin est correct

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export function PrettyLogin() {
  const dispatch = useDispatch<AppDispatch>()
  const { isLoading, error } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    const result = await dispatch(login({ email: values.email, password: values.password })).unwrap()
    if (result.message === "Connexion réussie") {
      navigate('/dashboard');
    }
  }

  return (
    <div className="min-h-screen flex">
      <div className="w-1/2 flex items-center justify-center bg-gradient-to-b from-white to-gray-50 p-12">
        <div className="w-full max-w-md space-y-8">
          <div className="flex flex-col items-center space-y-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <Lock className="h-8 w-8 text-primary" />
            </div>
            <h1 className="text-3xl font-bold text-gray-900">Bienvenue sur Picke.me</h1>
            <p className="text-gray-600 text-center">
              Connectez-vous pour gérer et monétiser votre contenu
            </p>
          </div>

          {error && (
            <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          <Form<FormValues>
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <Field name="email">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="email" className="text-sm font-medium text-gray-700">
                        Adresse email
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="email"
                          type="email"
                          placeholder="nom@exemple.com"
                          className="pl-10 w-full bg-white shadow-sm"
                          required
                        />
                        <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                      </div>
                      {meta.touched && meta.error &&
                        <span className="text-red-500 text-xs">{meta.error}</span>
                      }
                    </div>
                  )}
                </Field>

                <Field name="password">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="password" className="text-sm font-medium text-gray-700">
                        Mot de passe
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="password"
                          type="password"
                          placeholder="••••••••"
                          className="pl-10 w-full bg-white shadow-sm"
                          required
                        />
                        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                      </div>
                      {meta.touched && meta.error &&
                        <span className="text-red-500 text-xs">{meta.error}</span>
                      }
                    </div>
                  )}
                </Field>

                <div className="flex items-center justify-between">
                  <Field name="rememberMe" type="checkbox">
                    {({ input }) => (
                      <div className="flex items-center">
                        <input
                          {...input}
                          id="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        />
                        <Label htmlFor="remember-me" className="ml-2 text-sm text-gray-600">
                          Se souvenir de moi
                        </Label>
                      </div>
                    )}
                  </Field>
                  <Link
                    to="/forgot-password"
                    className="text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>

                <Button
                  type="submit"
                  className="w-full py-2.5 font-medium transition-all duration-200 hover:shadow-lg"
                  disabled={isLoading}
                >
                  {isLoading ? 'Connexion...' : 'Se connecter'}
                </Button>
              </form>
            )}
          />

          <div className="pt-6 text-center">
            <p className="text-sm text-gray-600">
              Vous n'avez pas de compte ?{" "}
              <Link
                to="/register"
                className="font-medium text-primary hover:text-primary/80 transition-colors"
              >
                Créer un compte
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="w-1/2 bg-cover bg-center" style={{
        backgroundImage: "url('https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-4.0.3')"
      }}>
      </div>
    </div>
  )
}
