import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { TooltipProvider } from "@/components/ui/tooltip";
import { PostsTable } from "@/components/services/posts/TablesPosts";
import { fetchPosts, setCurrentPage } from "@/app/reducers/PostsReducer";
import { AppDispatch, RootState } from "@/app/store";
import { Post } from "@/components/services/types/posts";
import { ThemeToggle } from "@/components/shared/ThemeToggle";
import AddPostModal from "@/components/services/posts/AddPostModal";
import EditPostModal from "@/components/services/posts/EditPostModal";


const DashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { posts, currentPage, totalPages, perPage, salesStats } = useSelector(
    (state: RootState) => state.posts
  );

  /* Gestion Modals */
  const [isAddPostModalOpen, setIsAddPostModalOpen] = useState(false);
  const [isEditPostModalOpen, setIsEditPostModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  const fetchPostsData = useCallback(() => {
    dispatch(fetchPosts({ page: currentPage, limit: perPage }));
  }, [dispatch, currentPage, perPage]);

  useEffect(() => {
    fetchPostsData();
  }, [fetchPostsData]);

  const handleEditPost = (post: Post) => {
    setSelectedPost(post);
    setIsEditPostModalOpen(true);
  };

  const handlePageChange = (newPage: number) => {
    dispatch(setCurrentPage(newPage));
  };

  return (
    <TooltipProvider>
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 p-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Tableau de bord</h1>
          <ThemeToggle />
        </div>

        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
          {/* Card 1 */}
          <Card className="sm:col-span-2">
            <CardHeader className="pb-3">
              <CardTitle>Vos Publications</CardTitle>
              <CardDescription className="max-w-lg text-balance leading-relaxed">
                Tableau de bord dynamique des publications pour une gestion et une
                analyse perspicace.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button onClick={() => setIsAddPostModalOpen(true)}>
                Créer une nouvelle publication
              </Button>
            </CardFooter>
          </Card>

          {/* Card 2 - Ventes de la semaine */}
          <Card>
            <CardHeader className="pb-2">
              <CardDescription>Ventes Cette Semaine</CardDescription>
              <CardTitle className="text-4xl">
                {salesStats?.total_sales_week?.toFixed(2) || '0.00'} €
              </CardTitle>
            </CardHeader>
            <CardContent>

            </CardContent>
            <CardFooter>
              <Progress
                value={salesStats?.total_sales_all_time && salesStats.total_sales_all_time > 0
                  ? (salesStats.total_sales_week / salesStats.total_sales_all_time) * 100
                  : 0}
                aria-label="Pourcentage du total des ventes"
              />
            </CardFooter>
          </Card>

          {/* Card 3 - Total des ventes */}
          <Card>
            <CardHeader className="pb-2">
              <CardDescription>Total des Ventes</CardDescription>
              <CardTitle className="text-4xl">
                {salesStats?.total_sales_all_time?.toFixed(2) || '0.00'} €
              </CardTitle>
            </CardHeader>
            <CardContent>

            </CardContent>
            <CardFooter>
              <Progress value={100} aria-label="Total des ventes" />
            </CardFooter>
          </Card>
        </div>

        {/* Card 4 */}
        <Card>
          <CardHeader>
            <CardTitle>Publications récentes</CardTitle>
            <CardDescription>
              Vous avez {posts.length} publications au total
            </CardDescription>
          </CardHeader>
          <CardContent>
            {posts.length > 0 && (
              <PostsTable
                postData={posts}
                onEditPost={handleEditPost}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </CardContent>
        </Card>
      </div>

      {/* Modals */}
      <AddPostModal
        isOpen={isAddPostModalOpen}
        onClose={() => setIsAddPostModalOpen(false)}
      />

      <EditPostModal
        isOpen={isEditPostModalOpen}
        onClose={() => setIsEditPostModalOpen(false)}
        post={selectedPost}
      />
    </TooltipProvider>
  );
};

export default DashboardPage;
