import React, { useState, useCallback, useEffect, useRef } from "react";
import Modal from "@/components/shared/Modal";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { Copy, Facebook, Twitter, Linkedin, Instagram } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import html2canvas from "html2canvas";

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  postUuid: string;
  postTitle?: string;
  postImage?: string;
}

// Déplacer le template en dehors du composant pour éviter les re-rendus inutiles
const createStoryTemplate = (
  content: { title: string; description: string },
  base64Image: string
) => `
  <div style="
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #3caa8e, #2a8870, #45c5a7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 60px 40px;
    color: white;
    font-family: system-ui;
  ">
    <div style="flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
      ${
        base64Image
          ? `
        <div style="
          width: 800px;
          height: 800px;
          border-radius: 20px;
          overflow: hidden;
          margin-bottom: 60px;
          box-shadow: 0 8px 30px rgba(0,0,0,0.2);
        ">
          <img
            src="${base64Image}"
            style="width: 100%; height: 100%; object-fit: cover;"
          />
        </div>
      `
          : ""
      }
      <div style="
        font-size: 72px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
        text-shadow: 0 2px 10px rgba(0,0,0,0.15);
        max-width: 900px;
        line-height: 1.2;
      ">
        ${content.title}
      </div>
      <div style="
        font-size: 48px;
        opacity: 0.9;
        text-align: center;
        max-width: 800px;
        line-height: 1.4;
        color: white;
      ">
        ${content.description}
      </div>
    </div>
  </div>
`;

function ShareModal({
  isOpen,
  onClose,
  postUuid,
  postTitle,
  postImage,
}: ShareModalProps) {
  const { toast } = useToast();
  const shareUrl = `${window.location.origin}/share/${postUuid}`;
  const [storyImage, setStoryImage] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [cachedBase64Image, setCachedBase64Image] = useState<string>("");
  const [storyContent, setStoryContent] = useState({
    title: postTitle || "",
    description: "",
  });
  const debounceTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (postImage && !cachedBase64Image) {
      loadImageAsBase64(postImage)
        .then((base64) => setCachedBase64Image(base64))
        .catch((error) => console.error("Failed to load image:", error));
    }
  }, [postImage]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast({
        title: "Lien copié !",
        description: "Le lien a été copié dans le presse-papier",
        variant: "default",
      });
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de copier le lien",
      });
    }
  };

  const handleContentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newContent = {
        ...storyContent,
        [e.target.name]: e.target.value,
      };

      setStoryContent(newContent);

      // Debounce la génération du canvas
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        generateStoryImageWithContent(newContent);
      }, 500); // Attendre 500ms après la dernière modification
    },
    [storyContent]
  );

  // Nettoyer le timeout lors du démontage
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const loadImageAsBase64 = async (imageUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        // Premier canvas pour le recadrage initial
        const canvas1 = document.createElement("canvas");
        const ctx1 = canvas1.getContext("2d");
        if (!ctx1) return reject(new Error("Failed to get context"));

        // Calculer les dimensions pour un carré
        const size = Math.min(img.width, img.height);
        const x = (img.width - size) / 2;
        const y = (img.height - size) / 2;

        // Ajouter une marge pour éviter les effets de bord du flou
        const margin = 100;
        canvas1.width = size + margin * 2;
        canvas1.height = size + margin * 2;

        // Étendre l'image en la répétant sur les bords
        for (let i = -1; i <= 1; i++) {
          for (let j = -1; j <= 1; j++) {
            ctx1.drawImage(
              img,
              x,
              y,
              size,
              size,
              margin + i * size,
              margin + j * size,
              size,
              size
            );
          }
        }

        // Appliquer le flou sur l'image étendue
        ctx1.filter = "blur(60px)";
        ctx1.drawImage(canvas1, 0, 0);

        // Deuxième canvas pour recadrer l'image finale
        const canvas2 = document.createElement("canvas");
        const ctx2 = canvas2.getContext("2d");
        if (!ctx2) return reject(new Error("Failed to get context"));

        canvas2.width = size;
        canvas2.height = size;

        // Recadrer en excluant les marges
        ctx2.drawImage(canvas1, margin, margin, size, size, 0, 0, size, size);

        resolve(canvas2.toDataURL("image/jpeg", 0.9));
      };
      img.onerror = () => reject(new Error("Failed to load image"));
      img.src = imageUrl;
    });
  };

  const generateStoryImageWithContent = useCallback(
    async (content: typeof storyContent) => {
      const storyTemplate = document.createElement("div");
      storyTemplate.style.width = "1080px";
      storyTemplate.style.height = "1920px";
      storyTemplate.style.position = "fixed";
      storyTemplate.style.left = "-9999px";
      storyTemplate.innerHTML = createStoryTemplate(content, cachedBase64Image);

      document.body.appendChild(storyTemplate);

      try {
        const canvas = await html2canvas(storyTemplate);
        const image = canvas.toDataURL("image/png");
        setStoryImage(image);
      } finally {
        document.body.removeChild(storyTemplate);
      }
    },
    [cachedBase64Image]
  );

  const handleInstagramClick = async () => {
    if (!storyImage) {
      await generateStoryImageWithContent(storyContent);
    }
    setShowPreview(!showPreview);
  };

  const downloadStory = () => {
    if (storyImage) {
      const link = document.createElement("a");
      link.href = storyImage;
      link.download = "instagram-story.png";
      link.click();
    }
  };

  const socialShare = (platform: string) => {
    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
    }
    window.open(url, "_blank", "width=600,height=400");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Partager la publication"
      className="max-w-[1000px] w-[90vw]"
    >
      <div className="flex gap-8 h-[600px]">
        {/* Colonne de gauche - Options de partage */}
        <div className="w-[300px] space-y-6">
          <div className="space-y-2">
            <Label>Lien de partage</Label>
            <div className="relative flex items-center">
              <Input value={shareUrl} readOnly className="pr-12" />
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-0 h-full aspect-square"
                onClick={handleCopy}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div className="space-y-2">
            <Label>Réseaux sociaux</Label>
            <div className="flex gap-4">
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => socialShare("facebook")}
              >
                <Facebook className="h-5 w-5 text-blue-600" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => socialShare("twitter")}
              >
                <Twitter className="h-5 w-5 text-blue-400" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => socialShare("linkedin")}
              >
                <Linkedin className="h-5 w-5 text-blue-700" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={handleInstagramClick}
              >
                <Instagram className="h-5 w-5 text-pink-600" />
              </Button>
            </div>
          </div>

          {showPreview && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="title">Titre de la story</Label>
                <Input
                  id="title"
                  name="title"
                  value={storyContent.title}
                  onChange={handleContentChange}
                  placeholder="Titre de votre story"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={storyContent.description}
                  onChange={handleContentChange}
                  placeholder="Description de votre story"
                  rows={2}
                />
              </div>

              <Button
                variant="outline"
                size="sm"
                onClick={downloadStory}
                className="w-full"
              >
                Télécharger la story
              </Button>
              <div className="space-y-2 border-t border-border pt-4">
                <Button
                  variant="outline"
                  size="sm"
                  className="w-full text-muted-foreground"
                  disabled
                >
                  Auto Upload
                </Button>
                <span className="text-xs text-muted-foreground">À venir</span>
              </div>
            </div>
          )}
        </div>

        {/* Colonne de droite - Prévisualisation */}
        <div className="flex-1 border-l border-border pl-8">
          <div className="h-full flex items-center justify-center border-2 border-dashed rounded-xl bg-muted/50">
            {showPreview && storyImage ? (
              <div className="relative w-[340px] h-[600px] p-2">
                <img
                  src={storyImage}
                  alt="Story Instagram"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            ) : (
              <div className="text-base text-muted-foreground text-center p-8">
                <div className="mb-4 text-4xl">📱</div>
                <div className="font-medium mb-2">
                  Prévisualisation de la story
                </div>
                <div className="text-sm">
                  Cliquez sur l'icône Instagram pour générer une story
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ShareModal;
