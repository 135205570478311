import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/app/store';
import { createStripeConnect, getStripeConnectStatus } from '@/app/reducers/UserReducers';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/hooks/use-toast';
import { Loader2 } from 'lucide-react';

export default function StripeConnectPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { toast } = useToast();
  const { status, isLoading, error } = useSelector(
    (state: RootState) => state.users
  );

  useEffect(() => {
    dispatch(getStripeConnectStatus());
  }, [dispatch]);

  useEffect(() => {
    if (status) {
      const toastConfig = {
        duration: 5000,
      };

      switch (status) {
        case 'active':
          toast({
            title: "Compte Stripe Connect actif",
            description: "Votre compte est configuré et prêt à recevoir des paiements.",
            ...toastConfig,
          });
          break;
        case 'pending':
          toast({
            title: "Configuration en cours",
            description: "Veuillez compléter la configuration de votre compte Stripe Connect.",
            ...toastConfig,
          });
          break;
        case 'not_connected':
          toast({
            variant: "destructive",
            title: "Compte non configuré",
            description: "Connectez votre compte Stripe pour recevoir des paiements.",
            ...toastConfig,
          });
          break;
      }
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error,
      });
    }
  }, [error]);

  const handleConnectStripe = async () => {
    try {
      const result = await dispatch(createStripeConnect()).unwrap();
      if (result.url) {
        window.location.href = result.url;
      }
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error.message || "Une erreur est survenue",
      });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Configuration Stripe Connect</h1>

      <Card className="p-6 mb-6">
        <div className="mt-6">
          <Button
            onClick={handleConnectStripe}
            disabled={isLoading || status === 'active'}
            className="w-full md:w-auto"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Configuration en cours...
              </>
            ) : status === 'active' ? (
              'Compte déjà configuré'
            ) : status === 'pending' ? (
              'Continuer la configuration'
            ) : (
              'Configurer Stripe Connect'
            )}
          </Button>
        </div>
      </Card>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">À propos de Stripe Connect</h2>
        <div className="prose dark:prose-invert">
          <p>
            Stripe Connect vous permet de recevoir des paiements en toute sécurité. 
            Pour commencer à recevoir des paiements, vous devez :
          </p>
          <ol>
            <li>Cliquer sur le bouton de configuration ci-dessus</li>
            <li>Remplir les informations demandées par Stripe</li>
            <li>Vérifier votre identité</li>
            <li>Ajouter vos informations bancaires</li>
          </ol>
        </div>
      </div>
    </div>
  );
}