import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Check, Linkedin } from "lucide-react";
import { LightBulbIcon } from "@/pages/Landing/Icons";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { Lock } from "lucide-react";
import { Share2 } from "lucide-react";

export const HeroCards = () => {
  return (
    <div className="hidden lg:flex flex-row flex-wrap gap-8 relative w-[700px] h-[500px]">
      {/* Testimonial */}
      <Card className="absolute w-[340px] -top-[15px] drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="flex flex-row items-center gap-4 pb-2">
          <Avatar>
            <AvatarImage
              alt=""
              src="https://api.dicebear.com/7.x/avataaars/svg?seed=jimmy"
            />
            <AvatarFallback>SH</AvatarFallback>
          </Avatar>

          <div className="flex flex-col">
            <CardTitle className="text-lg">Gabriel</CardTitle>
            <CardDescription>@gabriel_h</CardDescription>
          </div>
        </CardHeader>

        <CardContent>Plus besoins de chercher !</CardContent>
      </Card>

      {/* Team */}
      <Card className="absolute right-[20px] top-4 w-80 flex flex-col justify-center items-center drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="mt-8 flex justify-center items-center pb-2">
          <img
            src="https://i.pravatar.cc/150?img=3"
            alt="user avatar"
            className="absolute grayscale-[0%] -top-12 rounded-full w-24 h-24 aspect-square object-cover"
          />
          <CardTitle className="text-center">Gabriel</CardTitle>
          <CardDescription className="font-normal text-primary">
            vidéaste
          </CardDescription>
        </CardHeader>

        <CardContent className="text-center pb-2">
          <p>
            Ce qui est top, c'est que je n'ai pas besoin de demander à mes clients de créer un compte, donc c'est plus simple pour eux.
          </p>
        </CardContent>

      </Card>

      {/* Pricing */}
      <Card className="absolute top-[150px] left-[50px] w-72 drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader>
          <div className="relative aspect-square w-full rounded-lg overflow-hidden ">
            <img
              src="https://picsum.photos/seed/green/400/400"
              alt="Random preview"
              className="w-full h-full object-cover blur-xl"
            />
            <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
              <Lock className="w-12 h-12 text-white opacity-80" />
            </div>
          </div>


        </CardHeader>
        <CardContent className="text-center pb-4">
          <p className="text-lg font-semibold">
            Les backstages du dernier voyage au japon.
          </p>

        </CardContent>

        <CardContent>
          <Button className="w-full">Acheter maintenant</Button>
          <Button
            variant="outline"
            size="sm"
            className="w-full flex items-center justify-center gap-2 mt-2"
          >
            <Share2 className="h-4 w-4" />
            Partager
          </Button>
        </CardContent>


      </Card>

      {/* Service */}
      <Card className="absolute w-[350px] -right-[10px] bottom-[35px]  drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
          <div className="mt-1 bg-primary/20 p-1 rounded-2xl">
            <LightBulbIcon />
          </div>
          <div>
            <CardTitle>Mode sombre</CardTitle>
            <CardDescription className="text-md mt-2">
              On pense à vos yeux !
              Vous disposez d'un mode clair/sombre.
            </CardDescription>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};
