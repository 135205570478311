import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/app/store';
import { fetchPostByUuid, clearCurrentPost } from '@/app/reducers/PostsReducer';
import { Lock, ShieldCheck, Share2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useToast } from "@/hooks/use-toast";
import { Toaster } from '@/components/ui/toaster';
import { ThemeToggle } from "@/components/shared/ThemeToggle";
import { CheckoutButton } from '@/components/CheckoutButton';

export default function SharePage() {
  const { toast } = useToast();
  const { uuid } = useParams<{ uuid: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { currentPost, isLoading, error } = useSelector((state: RootState) => state.posts);

  const handleShare = async () => {
    const shareUrl = window.location.href;

    try {
      await navigator.clipboard.writeText(shareUrl);
      toast({
        title: "Lien copié !",
        description: "Le lien a été copié dans le presse-papier",
      });
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de copier le lien",
      });
    }
  };

  useEffect(() => {
    if (!uuid || !uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
      return;
    }
    dispatch(fetchPostByUuid(uuid));

    return () => {
      dispatch(clearCurrentPost());
    };
  }, [dispatch, uuid]);

  if (!uuid || !uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
    return <div>404</div>;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error || !currentPost) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <div className="max-w-md w-full space-y-4 text-center">
          <div className="bg-destructive/10 p-4 rounded-xl">
            <Lock className="h-12 w-12 mx-auto text-destructive" />
          </div>
          <h2 className="text-2xl font-bold text-foreground">Contenu non disponible</h2>
          <p className="text-muted-foreground">
            Ce contenu n'est plus accessible ou a été désactivé.
          </p>
          <Link to="/landing">
            <Button variant="outline" className="mt-4">
              Retourner à l'accueil
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  const timeAgo = formatDistanceToNow(new Date(currentPost.created_at), {
    addSuffix: true,
    locale: fr
  });

  return (
    <div className="min-h-screen bg-background">
      <Toaster />

      <nav className="border-b bg-card shadow-sm px-4 py-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <Link to="/landing" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
            <div className="bg-primary/10 p-2 rounded-lg">
              <Lock className="h-5 w-5 text-primary" />
            </div>
            <span className="text-xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
              Picke.me
            </span>
          </Link>
          <div className="flex items-center gap-4">
            <ThemeToggle />
          </div>
        </div>
      </nav>

      <div className="flex items-center justify-center p-4">
        <div className="max-w-md w-full space-y-8 bg-card rounded-xl shadow-lg p-6">
          <div className="relative aspect-square w-full rounded-lg overflow-hidden">
            <img
              src={currentPost.image_url}
              alt={currentPost.title}
              className="w-full h-full object-cover blur-xl"
            />
            <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
              <Lock className="w-12 h-12 text-white opacity-80" />
            </div>
          </div>

          <div className="text-center space-y-6">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold text-foreground">{currentPost.title}</h2>
            </div>

            <div className="bg-primary/5 py-4 rounded-xl">
              <div className="flex items-center justify-center gap-1">
                <span className="text-3xl font-bold text-primary">{currentPost.price}</span>
                <span className="text-2xl font-medium text-primary">€</span>
              </div>
              <p className="text-sm text-muted-foreground mt-1">Paiement unique</p>
            </div>

            <CheckoutButton
              postId={currentPost.id}
              price={Number(currentPost.price)}
              uuid={uuid}
              className="w-full py-6 text-lg font-semibold hover:scale-[1.02] transition-transform"
            />

            <Button
              variant="outline"
              size="sm"
              className="w-full flex items-center justify-center gap-2"
              onClick={handleShare}
            >
              <Share2 className="h-4 w-4" />
              Partager
            </Button>

            <div className="flex items-center justify-center space-x-2 text-sm text-muted-foreground">
              <ShieldCheck className="h-4 w-4" />
              <span>Paiement sécurisé</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
