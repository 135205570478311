import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { MagnifierIcon, WalletIcon, ChartIcon } from "@/pages/Landing/Icons";
import cubeLeg from "@/assets/cube-leg.png";
import { Headphones, RefreshCw } from "lucide-react";
import { MessageCircle } from "lucide-react";

interface ServiceProps {
  title: string;
  description: string;
  icon: JSX.Element;
}

const serviceList: ServiceProps[] = [
  {
    title: "Support client",
    description:
      "Notre \u00E9quipe est disponible pour r\u00E9pondre \u00E0 vos questions et vous accompagner dans l'utilisation de notre site.",
    icon: <MessageCircle size={40} className="text-primary" />,
  },
  {
    title: "Mise \u00E0 jour continue",
    description:
      "Le site est en constante \u00E9volution pour r\u00E9pondre \u00E0 vos besoins, n'h\u00E9sitez pas \u00E0 nous contacter si vous avez des suggestions.",
    icon: <RefreshCw size={48} className="text-primary" />,
  },
  {
    title: "Conseil personnalis\u00E9",
    description:
      "Notre \u00E9quipe est disponible pour vous conseiller et vous accompagner dans la cr\u00E9ation de votre contenu.",
    icon: <Headphones size={48} className="text-primary" />,
  },
];

export const Services = () => {
  return (
    <section className="container py-24 sm:py-32">
      <div className="grid lg:grid-cols-[1fr,1fr] gap-8 place-items-center">
        <div>
          <h2 className="text-3xl md:text-4xl font-bold">
            <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">
              Services{" "}
            </span>
            client
          </h2>

          <p className="text-muted-foreground text-xl mt-4 mb-8 ">
            Nous sommes une équipe francaise, et nous comprendons vos besoins et vos attentes.
          </p>

          <div className="flex flex-col gap-8">
            {serviceList.map(({ icon, title, description }: ServiceProps) => (
              <Card key={title}>
                <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
                  <div className="mt-1 bg-primary/20 p-1 rounded-2xl">
                    {icon}
                  </div>
                  <div>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription className="text-md mt-2">
                      {description}
                    </CardDescription>
                  </div>
                </CardHeader>
              </Card>
            ))}
          </div>
        </div>

        <img
          src={cubeLeg}
          className="w-[300px] md:w-[500px] lg:w-[600px] object-contain"
          alt="About services"
        />
      </div>
    </section>
  );
};
