import React from 'react'
import { Navbar } from '@/pages/Landing/Navbar'
import { Hero } from '@/pages/Landing/Hero'
import { Sponsors } from '@/pages/Landing/Sponsors'
import { About } from '@/pages/Landing/About'
import { Footer } from '@/pages/Landing/Footer'
import { HowItWorks } from '@/pages/Landing/HowItWorks'
import { Features } from '@/pages/Landing/Features'
import { Services } from '@/pages/Landing/Services'
import { Cta } from '@/pages/Landing/Cta'
import { Testimonials } from '@/pages/Landing/Testimonials'
import { Team } from '@/pages/Landing/Team'
import { FAQ } from '@/pages/Landing/FAQ'
import { Newsletter } from '@/pages/Landing/Newsletter'
import { Pricing } from '@/pages/Landing/Pricing'
import { ScrollToTop } from '@/pages/Landing/ScrollToTop'
function LandingPages() {
  return (
    <div>
      <Navbar />
      <Hero />
      {/* <Sponsors /> */}
      <About />
      <HowItWorks />
      <Features />
      <Services />
      <Cta />
      <Testimonials />
      <FAQ />
      <Footer />
      <ScrollToTop />
    </div>
  )
}

export default LandingPages
